<template>
    <div class="email-campaign">
        <div class="email-next">
            <div class="list-btns">
                <ul>
                    <li><a class="step1" v-bind:class="step == 1 ? 'active':''" href="#">Basic Info</a></li>
                    <li><a class="step2" v-bind:class="step == 2 ? 'active':''" href="#">Services</a></li>
                    <li><a class="step3" v-bind:class="step == 3 ? 'active':''" href="#">Set Schedule</a></li>
                    <span class="bar"></span>
                </ul>
            </div>
        </div>
        <form action="">
            <div class="email-step1" v-show="step == 1 ? 'active':''">
                <div class="step1-area">
                    <div class="email-deatils">
                        <h3>Basic Info</h3>
                        <div class="form-row mb-4">
                            <div class="form-group col-sm-12 col-md-8">
                                <label>Email <span style="font-size:11px">(Find email of the professional before you proceed)</span></label>
                                <div class="d-flex email-search">
                                    <input v-model="employee_.email" :class="[v$.employee_.email.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="Enter email">
                                    <a v-on:click.prevent="searchUser" href="#" class="rem-btn" >Search</a>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6 ">
                                <div class="form-group">
                                    <label >First name</label>
                                    <input v-model="employee_.first_name" :class="[v$.employee_.first_name.$error ? 'border-danger': '']" :disabled="isDisable" type="text" class="form-control" placeholder="First name" />
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <div class="form-group">
                                    <label >Last name</label>
                                    <input v-model="employee_.last_name" :class="[v$.employee_.last_name.$error ? 'border-danger': '']" :disabled="isDisable" type="text" class="form-control" placeholder="Last name" />
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6 ">
                                <div class="form-group">
                                    <label >Phone</label>
                                    <vue-tel-input v-model="employee_.phone" @country-changed="updateCountryCode" :class="[v$.employee_.phone.$error ? 'border-danger': '']" :onlyCountries="['US']" defaultCountry="US" v-mask="'(###) ### - ####'" :inputOptions="{type:'tel',placeholder:'(000) 000 - 0000'}" :dropdownOptions="{showDialCodeInList:true,showFlags:true,showDialCodeInSelection:true}" :autoFormat="false" mode="international"></vue-tel-input>
                                    <!-- <input v-model="employee_.phone" :class="[v$.employee_.phone.$error ? 'border-danger': '']" :disabled="isDisable" type="text" class="form-control" placeholder="Phone" /> -->
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <div class="form-group">
                                    <label >Gender</label>
                                    <select class="form-control" v-model="employee_.gender" :class="[v$.employee_.gender.$error ? 'border-danger': '']" :disabled="isDisable">
                                        <option value="">Choose gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6 ">
                                <div class="form-group">
                                    <label >Address</label>
                                    <input type="text" autoComplete="off" class="form-control" v-model="employee_.address.complete_address" :class="[v$.employee_.address.complete_address.$error ? 'border-danger': '']" placeholder="Enter your full business address" ref="origin" />
                                </div>
                            </div>
                            <div class="form-group col-sm-6 ">
                                <div class="form-group">
                                    <label>Profession</label>
                                    <select v-model="employee_.profession" :class="[v$.employee_.profession.$error ? 'border-danger': '']" class="form-control">
                                        <option value="">Choose profession</option>
                                        <option v-for="profession in professions" :key="profession.id" :value="profession.id">{{profession.title}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <div class="form-group">
                                    <label>Where will professional render the service?</label>
                                    <select v-model="employee_.render_location" :class="[v$.employee_.render_location.$error ? 'border-danger': '']" class="form-control">
                                        <option value="1">Business Location</option>
                                        <option value="2">Client Location</option>
                                        <option value="3">Both Business & Client Locations</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <div class="form-group">
                                    <label>Role</label>
                                    <select class="form-control" v-model="employee_.role" :class="[v$.employee_.role.$error ? 'border-danger': '']" >
                                        <option value="1">Employee</option>
                                        <option value="2">Manager</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6 ">
                                <div class="form-group">
                                    <label>License no:</label>
                                    <input type="text" class="form-control" v-model="employee_.licence" placeholder="Enter your business licence number" />
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <div class="form-group">
                                    <label>Licensing state</label>
                                    <select class="form-control" v-model="employee_.licence_state" :class="[v$.employee_.licence_state.$error ? 'border-danger': '']" :disabled="employee_.licence==''">
                                        <option value="">Choose state</option>
                                        <option v-for="state in states" :key="state.id" :value="state.id">{{state.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <div class="form-group">
                                    <label>Choose product</label>
                                    <select class="form-control" v-model="employee_.product" :class="[v$.employee_.product.$error ? 'border-danger': '']">
                                        <!-- <option value="">Choose product</option> -->
                                        <option value="2">Booking</option>
                                        <option value="1">Queue</option>
                                        <option value="3">Both Booking & Queuing </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <h3>
                                    <label class="switch">
                                        <input type="checkbox" v-model="employee_.status">
                                        <span class="slider round"></span>
                                    </label>
                                    Activate employee
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="email-step2" v-show="step == 2 ? 'active':''">
                <div class="email-deatils">
                    <h3>Assign Services</h3>
                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-6 col-lg-3" v-for="(service,index) in employee_.services" :key="index">
                            <label class="con-company" v-bind:class="service.selected ? 'currnt':''">
                                {{service.title}}
                                <input type="checkbox" v-model="service.selected" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="email-step3" v-show="step == 3 ? 'active':''">
                <div class="email-deatils">
                    <h3>Weekly Schedule</h3>
                    <div class="working-hours">
                        <div class="day-stripe">
                            <div class="day-hours-heading">
                                Day
                            </div>
                            <div class="hours-open-div">
                                <div class="day-hour-time-heading hours-open-div-wrapper ">
                                    Open
                                </div>
                            </div>
                            <div class="hours-open-div">
                                <div class="day-hour-time-heading hours-open-div-wrapper ">
                                    Close
                                </div>
                            </div>
                            <div class="hours-open-div break-div">
                                <div class="day-hour-time-heading hours-open-div-wrapper text-center">
                                    Break
                                </div>
                            </div>
                            <div class="hours-open-div">
                                <div class="day-hour-time-heading hours-open-div-wrapper ">
                                    Break Start
                                </div>
                            </div>
                            <div class="close-hr hours-open-div">
                                <div class="day-hour-time-heading hours-open-div-wrapper ">
                                    Break End
                                </div>
                            </div>
                        </div>
                        <div class="day-stripe" v-for="(schedule,index) in employee_schedule" :key="index">
                            <div class="day-hours-div">
                                <label class="switch">
                                    <input type="checkbox" v-model="schedule.isOpen" true-value="1" false-value="0">
                                    <span class="slider round"></span>
                                </label>
                                <span class="ml-2">{{schedule.title}}</span>
                            </div>
                            <div class="hours-open-div">
                                <div class="hours-open-div-wrapper">
                                    <input :class="'form-control timepicker'+schedule.day" :data-key="index" data-field="start_time" v-model="schedule.start_time" :disabled="schedule.isOpen == 0" placeholder="hh:mm a">
                                    <!-- <vue-timepicker v-model="schedule.start_time" :hour-range="schedule.hoursRange" @change="updateBreakHoursRange($event,index)" :disabled="schedule.isOpen == 0" format="HH:mm" :minute-interval="5" placeholder="hh:mm"></vue-timepicker> -->
                                </div>
                            </div>
                            <div class="hours-open-div">
                                <div class="hours-open-div-wrapper pull-left">
                                    <input :class="'form-control timepicker'+schedule.day" :data-key="index" data-field="end_time" v-model="schedule.end_time" :disabled="schedule.isOpen == 0" placeholder="hh:mm a">
                                    <!-- <vue-timepicker v-model="schedule.end_time" :hour-range="schedule.hoursRange" @change="updateBreakHoursRange($event,index)" :disabled="schedule.isOpen == 0" format="HH:mm" :minute-interval="5" placeholder="hh:mm"></vue-timepicker> -->
                                </div>
                            </div>
                            <div class="hours-open-div">
                                <div class="break-div-wrapper">
                                    <label class="switch">
                                        <input type="checkbox" v-model="schedule.isBreak" true-value="1" false-value="0" :disabled="schedule.isOpen == 0">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="hours-open-div">
                                <div class="hours-open-div-wrapper pull-left">
                                    <input :class="'form-control timepicker'+schedule.day" :data-key="index" data-field="break_start" v-model="schedule.break_start" :disabled="schedule.isBreak == 0" placeholder="hh:mm a">
                                </div>
                            </div>
                            <div class="close-hr hours-open-div">
                                <div class="hours-open-div-wrapper pull-left">
                                    <input :class="'form-control timepicker'+schedule.day" :data-key="index" data-field="break_end" v-model="schedule.break_end" :disabled="schedule.isBreak == 0" placeholder="hh:mm a">
                                </div>
                            </div>
                            <div class="pull-right time-error">
                                <span v-show="schedule.hasError">{{schedule.errorMsg}}</span>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="mt-3 text-right col-sm-12">
                                <button class="rem-btn pull-right" v-on:click="updateSchedule">Update</button>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="pt-4" style="border-top: 1px solid #D5D8DF;">
                        <a class="add-btn float-left pr-5 pl-5" v-bind:class="step == 1 ? 'd-none' : ''" href="#" v-on:click="step = step-1">Back</a>
                        <a class="rem-btn float-right pr-5 pl-5" href="#" v-on:click.prevent="addEmployee">
                            <span v-if="step < 3">next</span><span v-if="step == 3">Submit</span>
                        </a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import {mask} from 'vue-the-mask'
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
// import VueTimepicker from 'vue3-timepicker'
// import 'vue3-timepicker/dist/VueTimepicker.css'
import useVuelidate from '@vuelidate/core'
import { required,email,requiredIf } from '@vuelidate/validators'
import { useToast } from "vue-toastification";
import axios from "axios";

import $ from 'jquery';
import "jquery-datetimepicker/jquery.datetimepicker.js";
import "jquery-datetimepicker/jquery.datetimepicker.css";
import moment from 'moment'

export default {
    name: "AddEmployee",
    components: {
        VueTelInput,
        //VueTimepicker
    },
    directives: {mask},
    setup () {
        const toast = useToast();
        return { v$: useVuelidate(),toast }
    },
    data() {
        return {
            step: 1,
            pathArr:"/",
            isDisable:true,
            employee_:{
                email:'',
                first_name:'',
                last_name:'',
                address:{
                    complete_address:'',
                    lat:'',
                    lng:'',
                    country:'',
                    state:'',
                    city:'',
                    street:'',
                    zip:''
                },
                phone:'',
                dialCode:1,
                gender:'',
                profession:'',
                render_location:1,
                role:1,
                services:[],
                licence:'',
                licence_state:'',
                product:2,
                schedule:[],
                status:true,
                business_id:this.$storage.getStorageSync('business').active_business.id
            },
            employeeSchedule:[],
            errorMsg : false,
            states:[],
            professions:[],
            //days:["", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
        }
    },
    validations () {
        return {
            employee_:{
                email:{ required,email },
                first_name:{ required },
                last_name:{ required },
                phone:{ required },
                gender:{ required },
                profession:{ required },
                render_location:{ required },
                role:{ required },
                address:{
                    complete_address:{ required }
                },
                product:{ required },
                // licence:{ required },
                licence_state:{
                    requiredIfFoo: requiredIf(this.employee_.licence != ''),
                }
            }
        }
    },
    created() {
        //this.pathArr = this.$route.path.split("/");

        this.getServices()
        this.getStates()
        this.getProfessions()

        window.jQuery.datetimepicker.setDateFormatter({
            parseDate: function (date, format) {
                var d = moment(date, format);
                return d.isValid() ? d.toDate() : false;
            },
            formatDate: function (date, format) {
                return moment(date).format(format);
            }
        });

        // for (let i=1; i < 8; i++) {
        //     var empHoursArr = {
        //         'title':this.days[i],
        //         'day': i,
        //         'isOpen':0,
        //         'start_time':'',
        //         'end_time':'',
        //         'isBreak':0,
        //         'breakStart' : '',
        //         'breakEnd' : '',
        //         //'hoursRange' : '',
        //         //'breakHoursRange' : '',
        //         'hasError':false,
        //         "errorMsg":''
        //     }
        //     this.employeeSchedule.push(empHoursArr)
        // }
    },
    methods: {
        async addEmployee() {
            console.log(this.employeeSchedule)
            if (this.step == 1) {
                this.v$.employee_.$touch()
                //if (!this.v$.employee_.first_name.$error && !this.v$.employee_.last_name.$error && !this.v$.employee_.email.$error && !this.v$.employee_.phone.$error && !this.v$.employee_.gender.$error && !this.v$.employee_.render_location.$error && !this.v$.employee_.role.$error && !this.v$.employee_.profession.$error) {
                if (!this.v$.employee_.$error){
                    this.step++
                }
            }else if (this.step == 2) {
                this.step++
            }else if (this.step == 3) {
                this.employee_.schedule = this.employeeSchedule
                let hasError = false
                //console.log(this.employee_)
                await this.employeeSchedule.forEach((value,index) => {
                    if (value.isOpen == 1) {
                        var open = value.start_time;
                        var close = value.end_time;
                        var stt = new Date("November 13, 2019 " + open);
                        stt = stt.getTime();
                        var endt = new Date("November 13, 2019 " + close);
                        endt = endt.getTime();
                        if(stt >= endt) {
                            this.employeeSchedule[index].hasError = true
                            this.employeeSchedule[index].errorMsg = "Close time must be greater then Open time."
                            hasError = true
                        }else{
                            if (value.isBreak == 1) {
                                var bopen = value.break_start;
                                var bclose = value.break_end;
                                var bst = new Date("November 13, 2019 " + bopen);
                                bst = bst.getTime();
                                var bend = new Date("November 13, 2019 " + bclose);
                                bend = bend.getTime();
                                if(bst >= bend) {
                                    this.employeeSchedule[index].hasError = true
                                    this.employeeSchedule[index].errorMsg = "Break End time must be greater then Break Start time."
                                    hasError = true
                                }else{
                                    this.employeeSchedule[index].hasError = false
                                }
                            }else{
                                this.employeeSchedule[index].hasError = false
                            }
                        }
                    }
                });
                if (hasError === false) {
                    let thiss = this
                    axios.post('addEmployee',this.employee_).then(function (response) {
                        if (response.data.status == 'success') {
                            thiss.$router.push({ name: 'Employees'})
                        }else if (response.data.status == 'login') {
                            thiss.$storage.removeStorageSync("user")
                            thiss.$storage.removeStorageSync("token")
                            thiss.$storage.removeStorageSync("business")
                            thiss.$router.push({ name: 'Home'})
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 422) {
                                this.toast.error(error.response.data.message);
                            }else if (error.response.status == 401) {
                                this.toast.error(error.response.data.message,{position: "top-right"});
                            }
                        }
                    });
                }
            }
        },
        searchUser(){
            if (this.employee_.email != '') {
                let thiss = this
                axios.post('getUser/forEmployee',{email:this.employee_.email}).then(function (response) {
                    if (response.data.status == 'success') {
                        let user = response.data.data
                        thiss.employee_.first_name = user.first_name
                        thiss.employee_.last_name = user.last_name
                        thiss.employee_.phone = user.phone.substr(user.phone.length - 10);
                        thiss.employee_.dialCode = user.phone.replace(user.phone.substr(user.phone.length - 10),'');
                        thiss.employee_.gender = user.gender
                        thiss.isDisable = true
                    }else{
                        thiss.isDisable = false
                    }
                    console.log(thiss.employee_)
                })
                .catch(error => {
                    if (error.response) {
                        thiss.isDisable = false
                        thiss.employee_.first_name = ''
                        thiss.employee_.last_name = ''
                        thiss.employee_.phone = ''
                        thiss.employee_.dialCode = 1
                        thiss.employee_.gender = ''
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401) {
                            let msg = ''
                            if (error.response.data.data == 'already_exist') {
                                msg = error.response.data.message
                            }else{
                                msg = error.response.data.message+' Please enter other detail.'
                            }
                            this.toast.error(msg,{position: "top-right"});
                        }
                    }
                });
            }
        },
        getServices(){
            let thiss = this
            axios.get('businessServices').then(function (response) {
                if (response.data.status == 'success') {
                    var data = []
                    response.data.data.forEach((serv) => {
                        data.push({ si:serv.service_id, bsi:serv.id, title:serv.service.title, selected:false})
                    });
                    thiss.employee_.services = data
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        },
        updateCountryCode(data){
            if (data.dialCode) {
                this.employee_.dialCode = data.dialCode
            }
        },
        getStates(){
            let thiss = this
            axios.get('states').then(function (response) {
                if (response.data.status == 'success') {
                    thiss.states = response.data.data
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        },
        getProfessions(){
            let thiss = this
            axios.get('professions').then(function (response) {
                if (response.data.status == 'success') {
                    thiss.professions = response.data.data
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        },
        getAddressArray(place){
            var comp_address = {complete_address:'',lat:'',lng:'',street:'',city:'',state:'',country:'',zip:''}
            comp_address.complete_address = place.formatted_address
            comp_address.lat = place.geometry.location.lat()
            comp_address.lng = place.geometry.location.lng()
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (addressType == "street_number") {
                    comp_address.street += place.address_components[i]['short_name']
                }else if (addressType == "route") {
                    comp_address.street += ' '+place.address_components[i]['long_name']+' '
                }else if (addressType == "locality") {
                    comp_address.city = place.address_components[i]['long_name']
                }else if (addressType == "administrative_area_level_1") {
                    comp_address.state = place.address_components[i]['short_name']
                }else if (addressType == "country") {
                    comp_address.country = place.address_components[i]['long_name']
                }else if (addressType == "postal_code") {
                    comp_address.zip = place.address_components[i]['short_name']
                }
            }
            this.employee_.address = comp_address
        },
        getSchedule(){
            let thiss= this
            axios.get('business_hours/'+this.$storage.getStorageSync('business').active_business.id+'/employee').then(function (response) {
                if (response.data.status == 'success') {
                    //console.log(response.data.data)
                    thiss.employeeSchedule = response.data.data
                    setTimeout(function() {
                        thiss.employeeSchedule.forEach((item) => {
                            $('.timepicker'+item.day).datetimepicker({
                                datepicker:false,
                                format:'h:mm A',
                                formatTime:'h:mm A',
                                todayButton: false,
                                allowTimes:item.active_hours,
                                onChangeDateTime:function(dp,$input){
                                    console.log(dp);
                                    thiss.employeeSchedule[$input.data('key')][$input.data('field')] = $input.val()
                                }
                            });
                        });
                    }, 500);
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.toast.error(error.response.data.message);
                    }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push({ name: 'Home'})
                    }
                }
            });
        },
        //updateBreakHoursRange(resp,index){
            //console.log(resp,index)
            // if (this.employeeSchedule[index].start_time.HH != '' && this.employeeSchedule[index].start_time.HH != null && this.employeeSchedule[index].start_time.HH != undefined && this.employeeSchedule[index].start_time.HH != 'undefined' && this.employeeSchedule[index].end_time.HH != '' && this.employeeSchedule[index].end_time.HH != null && this.employeeSchedule[index].end_time.HH != undefined && this.employeeSchedule[index].end_time.HH != 'undefined') {
            //     if (this.employeeSchedule[index].end_time.HH > this.employeeSchedule[index].start_time.HH) {
            //         this.employeeSchedule[index].breakHoursRange = [[this.employeeSchedule[index].start_time.HH,this.employeeSchedule[index].end_time.HH]]
            //     }
            // }
        //}
    },
    mounted() {
        // Configer Get Data From Autocomplete
        let thiss = this
        const autocomplete = new window.google.maps.places.Autocomplete(this.$refs["origin"],{componentRestrictions:{country:['US','CA','PK']}});
        autocomplete.addListener('place_changed', function () {
            const place = autocomplete.getPlace();
            // Get Complete Address Array
            thiss.getAddressArray(place)
        });
        this.getSchedule()
    },
    computed:{
        employee_schedule(){
            return this.employeeSchedule.filter(i => i.status == 1)
        }
    }
};
</script>
<style scoped>
    .form-group label{
        text-transform: unset;

    }
    .email-search input{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .email-search a{
        border-radius: .25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .working-hours{
        margin-bottom: 15px;
        padding: 0;
    }
    .time-picker-overlay{
        outline: none;
    }

    .day-stripe{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-right:15px;padding-left:15px;-webkit-box-align:stretch;-webkit-align-items:stretch;-ms-flex-align:stretch;align-items:stretch;border-bottom:1px solid rgba(0,0,0,.1);-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap}

    .day-hours-div,.day-hours-heading {
        width: 19%;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        border-right: 1px solid rgba(0, 0, 0, .1);
        font-size: 16px
    }
    .day-hours-div{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .day-hours-heading,.day-hour-time-heading{font-weight: 800;}
    .day-hour-time-heading{
        padding: 10px 18px !important;
    }
    .day-hours-div label.vue-js-switch{
        margin-right: 15px;
    }
    .day-hours-div .v-switch-label{
        text-transform: uppercase;
    }
    .hours-open-div {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 18%;
        border-right: 1px solid rgba(0, 0, 0, .1)
    }
    .break-div{
        width: 8% !important;
    }

    .hours-open-div.close-hr {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: block;
        border-right-style: none
    }
    .hours-open-div-wrapper{
        padding: 10px 12px;
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 0;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .break-div-wrapper{
        padding: 15px;
        position: relative;
        display: block;
        margin-bottom: 0;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .time-error{
        color: #eb6663;
        padding: 0;
        width: 100%;
    }
    @media screen and (max-width: 991px) {
        .email-deatils h3 {
            font-size: 16px;
        }
        .form-group label {
            font-size: 12px;
        }
    }
    @media screen and (max-width: 576px) {
        .email-campaign {
            padding: 15px;
        }
    }
    /* .hours-open-div-wrapper img{
        margin-top: 3px;
        width: 14px;
        position: absolute;
        right: 13px;
        top: 18px;
        opacity: 0.6;
        z-index: 1;
    }
    .working-hours{
        margin-top: 25px;
        border: 1px solid #0000001a;
        border-radius: 5px;
        box-shadow: 1px 1px 3px 0 rgba(0,0,0,.2);
    }
    .switch-checkbox{
        margin-right: 15px;
        margin-bottom: 0;
    } */


    /* .vue__time-picker input.display-time{
        margin: 0;
        font-size: 18px;
        width: 100%;
        color: #353535;
        border: none;
        font-weight: 400;
        cursor: pointer;
        z-index: 2;
        background: #0000;
        cursor: pointer;
    }
    .vue__time-picker input.display-time:focus{
    border:none;
    outline: none;
    cursor: pointer;
    }
    .vue__time-picker input.display-time::-webkit-input-placeholder,
    .vue__time-picker input.display-time::-ms-input-placeholder,
    .vue__time-picker input.display-time::placeholder,
    .vue__time-picker input.display-time:-ms-input-placeholder {
    color: #bbb;
    }
    div.error-message-form,div.plan-msg {
        position: absolute;
        background: #de2f2f;
        padding: 4px 8px;
        min-width: 160px;
        font-size: 14px;
        font-weight: 300;
        border-radius: 0;
        color: #fff;
        left: 0px;
        z-index: 10;
        top: 65px;
        box-shadow: 0px 2px 3px -1px #000;
    }
    .input-group div.error-message-form{
        top: 33px;
        left: 30px;
    }
    div.error-message-form:before,div.plan-msg:before {
        bottom: 100%;
        left: 30px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(232, 232, 232, 0);
        border-bottom-color: #de2f2f;
        border-width: 7px;
        margin-left: -7px;
    }
    .error-message-form .fa-sort-asc {
        top: -9px !important;
        font-size: 27px !important;
        color: #e8e8e8 !important;
        left: 10px !important;
    } */
</style>
<style>
    .vue__time-picker, .vue__time-picker input.vue__time-picker-input{
        width: 100% !important;
        max-width: 120px;
    }
    .xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div:hover{
        background:#6e317a !important
    }
    .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current{
        background: #6e317a;
        box-shadow: #6e317a 0 1px 3px 0 inset;
    }
</style>
<style src="@vueform/toggle/themes/default.css"></style>