<template>
    <AppContainer>
        <AddEmployee/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import AddEmployee from '@/components/dashboard/employees/AddEmployee.vue'
export default {
  components: {
    AppContainer,
    AddEmployee
  }
}
</script>